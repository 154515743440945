import axios, { AxiosInstance } from 'axios'
import { ApiTokenRefresher } from '@wavesenterprise/api-token-refresher'
import { ICreateTokenResponse } from './ApiInterfaces'

const AUTH_SERVICE_ADDRESS = '/authServiceAddress/v1'
const NODE_ADDRESS = '/nodeAddress'
const API_ADDRESS = '/apiAddress'
const KEYS_ADDRESS = '/keysAddress'
const source = 'shortday-nft-client'

export type ITokenPair = {
  access_token: string,
  refresh_token: string,
  token_type: string,
}

export class API {
  private _unauthorizedClient: AxiosInstance = axios.create()
  private _apiClient!: AxiosInstance

  createAxiosWithRefresher(tokenPair: ITokenPair, onRefreshFailed: () => void) {
    const refreshCallback = async (token: string) => {
      try {
        const { data } = await axios.post(`${AUTH_SERVICE_ADDRESS}/auth/refresh`, { token })
        // console.log('JWT token refreshed')
        return data
      } catch (e) {
        console.error('JWT token refresh failed:', (e as Error).message)
        onRefreshFailed()
      }
    }

    const apiTokenRefresher: ApiTokenRefresher = new ApiTokenRefresher({
      maxAttemptsToRefreshToken: 1,
      authorization: tokenPair,
      refreshCallback,
    })
    return apiTokenRefresher.init()
  }

  setupApi(refresherAxios: any) {
    this._apiClient = refresherAxios
  }

  // Auth requests
  signIn  = async (username: string, password: string): Promise<ITokenPair> => {
    const { data: tokenPair } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/auth/login`, { username, password })
    return tokenPair
  }

  signUp  = async (username: string, password: string): Promise<ITokenPair> => {
    const { data } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/user`, { username, password, source })
    return data
  }

  changePassword  = async (password: string): Promise<ITokenPair> => {
    const { data } = await this._apiClient.post(`${AUTH_SERVICE_ADDRESS}/user/password/change`, {
      password,
      passwordRepeat: password,
    })
    return data
  }

  sendPasswordRecover = async (email: string) => {
    const { data } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/user/password/restore`, { email, source })
    return data
  }

  resetPassword = async (token: string, password: string) => {
    const { data } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/user/password/reset`, { token, password })
    return data
  }

  confirmUser = async (token: string) => {
    const { data } = await this._unauthorizedClient.get(`${AUTH_SERVICE_ADDRESS}/user/confirm/${token}`)
    return data
  }

  getUserProfile = async () => {
    const { data } = await this._apiClient.get(`${AUTH_SERVICE_ADDRESS}/user/profile`)
    return data
  }

  // Node requests
  getNodeConfig = async () => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/node/config`)
    return data
  }

  getAddressBalance = async (address: string) => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/addresses/balance/details/${address}`)
    return data
  }

  // Service requests
  getCollections = async () => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/collection`)
    return data
  }

  getCollection = async (id: string) => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/collection/${id}`)
    return data
  }

  getImages = async () => {
    const { data } = await this._apiClient.get(`${API_ADDRESS}/image`)
    return data
  }

  getImage = async (id: string) => {
    const { data } = await this._apiClient.get(`${API_ADDRESS}/image/${id}`)
    return data
  }

  uploadImage = async (image: File) => {
    const formData = new FormData()
    formData.append('file', image)

    const { data } = await this._apiClient.post(`${API_ADDRESS}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data
  }

  getTags = async (params: { tokenId?: string, name?: string }) => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/tag`, { params })
    return data
  }

  createTag = async (tokenId: string, name: string) => {
    const { data } = await this._apiClient.post(`${API_ADDRESS}/tag`, { tokenId, name })
    return data
  }

  getTokens = async (page?: number, size?: number) => {
    const { data, headers } = await this._unauthorizedClient.get(`${API_ADDRESS}/token${page && size ? '?pageNo=' + page + '&size=' + size : ''}`)
    return { data, count: Number(headers['x-total-count']) }
  }

  getToken = async (id: string) => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/token/${id}`)
    return data
  }

  getTokenInfo = async (id: string) => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/token/${id}/info`)
    return data
  }

  createToken = async (response: ICreateTokenResponse) => {
    const { data } = await this._apiClient.post(`${API_ADDRESS}/token`, response)
    return data
  }

  getTokensByAddress = async (address: string) => {
    const { data } = await this._unauthorizedClient.get(`${API_ADDRESS}/token/ownedBy/${address}`)
    return data
  }

  claimToken = async (code: string, recipient: string) => {
    const { data } = await this._apiClient.post(`${API_ADDRESS}/token/claim`, {
      code,
      recipient,
    })
    return data
  }

  // Keys requests
  createKey = async (name: string) => {
    const { data: { result } } = await this._apiClient.post(`${KEYS_ADDRESS}/keys/${name}`)
    return result
  }

  getKey = async (name: string) => {
    const { data: { result } } = await this._apiClient.get(`${KEYS_ADDRESS}/keys/${name}`)
    return result
  }

  broadcast = async (tx: any) => {
    const { data } = await this._apiClient.post(`${KEYS_ADDRESS}/keys/broadcast`, tx)
    return data
  }

  // Files requests
  getFile = async (hash: string) => {
    const response = await fetch(`${API_ADDRESS}/file/${hash}`)
    const blob = await response.blob()
    if (response.status !== 200 || blob.type !== 'video/mp4') {
      throw new Error()
    }
    const downloadUrl = window.URL.createObjectURL(blob)
    return downloadUrl
  }
}
